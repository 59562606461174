import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tema",
      "style": {
        "position": "relative"
      }
    }}>{`Tema`}</h1>
    <p>{`Bold exporta por padrão dois temas: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lightTheme`}</code>{` e `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`darkTheme`}</code>{`, utilizando o primeiro como tema padrão.`}</p>
    <p>{`Você pode trocar o tema deste site de documentação entre light/dark usando a lâmpada no canto superior direito da tela.`}</p>
    <h2 {...{
      "id": "customizando-o-tema",
      "style": {
        "position": "relative"
      }
    }}>{`Customizando o tema`}</h2>
    <p>{`O tema pode ser alterado usando o componente `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ThemeProvider`}</code>{`:`}</p>
    <Demo src='pages/usage/theme/Customize' mdxType="Demo" />
    <h2 {...{
      "id": "acessando-o-tema-atual",
      "style": {
        "position": "relative"
      }
    }}>{`Acessando o tema atual`}</h2>
    <p>{`Use as hooks `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useTheme`}</code>{` ou `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStyles`}</code>{` para acessar a variável do tema atual:`}</p>
    <Demo src='pages/usage/theme/AccessTheme' mdxType="Demo" />
    <h2 {...{
      "id": "temas-personalizados",
      "style": {
        "position": "relative"
      }
    }}>{`Temas personalizados`}</h2>
    <p>{`É possível criar um tema personalizado usando a função `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createTheme`}</code>{`:`}</p>
    <Demo src='pages/usage/theme/CustomTheme' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      